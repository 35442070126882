import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Login";
import Relay from "./pages/Relay";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
    <div className="App">
      {/* <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">
          <Link className="navbar-brand" to={"/relay"}>positronX.io</Link>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to={"/relay"}>Relay</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

      
          <Switch>
            <Route exact path='/' component={Login} />
            <Route path="/relay" component={Relay} />
          </Switch>
        
    </div></Router>
  );
}

export default App;
