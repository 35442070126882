import React, { Component } from "react";
import { useHistory } from "react-router-dom";
export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      its: "",
    };
  }
  handleClick = () => {
    console.log(this.state.its);
    this.props.history.push({
      pathname: "/relay",
      state: { its: this.state.its },
    });
  };
  render() {
    return (
      <div className="auth-wrapper">
        <div className="auth-inner">
          <form onSubmit={this.handleClick}>
            <h4>Sign in with your ITS</h4>
            <div className="form-group">
              <input
                type="number"
                required
                maxLength="8"
                className="form-control"
                placeholder="Enter ITS"
                value={this.state.its}
                onChange={(e) => {
                  this.setState({ its: e.target.value });
                }}
              />
            </div>
            <button
              type={"submit"}
              className="btn btn-primary btn-block"
              style={{ marginTop: 20 }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}
