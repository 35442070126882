import React, { Component } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
export default class Relay extends Component {
  render() {
    let its = this.props.location.state.its;
    let url = "http://164.132.162.99/movie/" + its + "/" + its + "/8938.mp4"
    return (
      <div style={{display:'flex', flexDirection:'column', justifyContent: "center", alignItems: "center" }}>
        <h1>Relay for {its}</h1>
        <div>
          <Video
          style={{ justifyContent: "center", alignItems: "center"}}
            autoPlay
            loop
            muted
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            poster="http://sourceposter.jpg"
            onCanPlayThrough={() => {
              // Do stuff
            }}
            style={{
              borderWidth: 1,
              width: window.innerWidth * 0.8,
              height: window.innerHeight * 0.8,
            }}
          >
            <source
              src={url}
              type="video/mp4"
            />
          </Video>
        </div>
      </div>
    );
  }
}
